<template>
  <div class="e-content">
    <div id="Introduction">
      <h1>Introduction</h1>
      <p>enterBlock - Vue2, CLI, Vue Router 그리고 Vuex를 이용하여 제작한 홈페이지입니다.
      </p>

      <b-card title="About Site">
        <b-card-text>enterBlock 사이트는 Vue2, CLI, Vue Router 그리고 Vuex 구성요소 프레임워크를 기반으로 하는 사이트입니다.</b-card-text>
        <b-card-text>Home, NFT, NODE, DeFi 4개의 페이지로 되어 있으며, Home 페이지의 경우 5개의 섹션으로 나누어져 있습니다. 상단의 메뉴바를 통해 각 페이지로 이동할 수
          있습니다.
        </b-card-text>
        <b-card-text>엔터블록 사이트 바로가기 :
          <b-link
              href="https://enterblock.co/"
              target="_blank"
          >
            https://enterblock.co/
          </b-link>
        </b-card-text>
      </b-card>
    </div>



    <b-card id="Technical Specification" title="Technical Specification">
      <b-card-text>
        <ul class="e-list">
          <li>Vue2</li>
          <li>Vue CLI 4</li>
          <li>Vuex</li>
          <li>Vue Router</li>
          <li>Axios</li>
          <li>SASS</li>
          <li>Vue Sweet Alert 2</li>
          <li>Telegram APIs</li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/home-first-section.jpg"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/home-second-section.jpg"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/home-third-section.jpg"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/home-fourth-section.jpg"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/home-fifth-section.jpg"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/nftPage.png"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/nodePage.png"></b-img>
      <br/> <br/>
      <b-img class="my-2 enter-img" center src="@/assets/images/pages/defiPage.png"></b-img>
    </b-card>
  </div>
</template>

<script>
import {BCard, BCardText, BLink, BImg} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Introduction','TechnicalSpecification'],
      codeNpm:
          `
# For Yarn
yarn

# For npm
npm install
  `,
      codeNpmRun:
          `
# For Yarn
yarn serve

# For npm
npm run serve
  `,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  }
}
</script>

<style>
.enter-img {
  width: 100%;
  min-width: 1100px
}
@media (max-width: 1780px) {
  .enter-img {
    width: 100%;
    min-width: 800px
  }
}
@media (max-width: 1476px) {
  .enter-img {
    width: 100%;
    min-width: 600px
  }
}

@media (max-width: 1276px) {
  .enter-img {
    width: 100%;
    min-width: 400px
  }
}

@media (max-width: 493px) {
  .enter-img {
    width: 100%;
    min-width: 150px
  }
}
</style>